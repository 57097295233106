#bootstrapoverride .exhibition-schedule-container {
	max-width: 1070px;

	.page-title {
		margin-bottom: 40px; }

	.map-container {
		width: 100%;
		height: 480px;
		margin-bottom: 60px; }


	.search-controls-wrapper {
		margin-bottom: 20px;
		padding: 0 25px;
		height: 50px;

		.dropdown-wrapper {
			width: 180px;
			margin-right: 15px;
			float: left; }

		.datepick-wrapper {
			position: relative;
			width: 210px;
			margin-right: 15px;
			float: left;

			input[name="daterange"] {
				width: 100%; }

			.fa {
				position: absolute;
				top: 6px;
				right: 8px;
				pointer-events: none;
				background-color: white;
				padding: 0px 5px; } }


		.search-wrapper {
			width: 210px;
			margin-right: 15px;
			float: left;

			.fa {
				position: absolute;
				top: 7px;
				right: 12px;
				font-size: 12px;
				color: #989898;
				pointer-events: none; } } }


	.exhibition-container {
		margin-bottom: 30px;
		padding: 20px;
		background-color: white;

		.row {
			display: flex;
			align-items: flex-end;
			margin-bottom: 20px; }

		.row>div {
			overflow: hidden; }

		h3 {
			position: relative;
			margin-bottom: 20px;

			&:after {
				position: absolute;
				content: "";
				bottom: -10px;
				left: 0;
				width: 40px;
				height: 2px;
				background-color: $theme-orange; }

			span {
				display: block;
				font-size: 18px;
				line-height: 25px;
				color: #141414;
				font-weight: bold; } }


		span {
			display: inline-block;
			color: #777777; }

		span.fa {
			margin-right: 8px;
			color: #989898; }

		span.fa-map-marker {
			margin: 0px 10px 0px 3px; }


		.description {
			font-weight: bold;
			margin-right: 12px; }

		.date {
			margin-right: 30px;
			margin-bottom: 8px;

			&.exhibition-date .fa {
				display: none; }

			&.exhibition-date .description {
				display: none; } }

		.exhibition-date {
			color: #141414; }

		.products {
			margin-right: 30px;
			margin-bottom: 8px; }

		.company-name>a {
			color: #027eba;
			text-decoration: underline; }

		&:hover {
			cursor: pointer; }

		.agent,
		.company-name {
			margin-bottom: 4px; }

		.btn {
			display: block;
			margin-left: 0; } }


	.infowindow {
		span {
			display: inline-block;
			font-size: 12px; }

		h3 {
			color: #141414;
			font-size: 14px;
			line-height: 20px;
			font-weight: bold;
			margin-bottom: 10px;

			span {
				display: block; } }

		.text-wrap {
			width: 220px; }

		.description {
			font-weight: bold;
			margin-right: 12px; }

		.fa {
			width: 15px;
			margin-right: 10px;
			color: #989898;
			text-align: center; }

		p {
			display: flex;
			color: #777777;
			font-size: 12px;
			line-height: 22px; }


		a {
			color: #027eba;
			text-decoration: underline;
			font-size: 12px; } }


	.infowindow-nav {
		text-align: center;
		margin-bottom: 15px;

		.page-number {
			font-size: 12px; }

		.prev-infowindow,
		.next-infowindow {
			display: inline-block;
			width: 15px;
			height: 15px;
			margin: 0 10px;
			font-family: "fa";
			color: #777777;
			font-size: 11px;
			cursor: pointer; } }

	.exhibition-list-container {
		.copyright_content {
			text-align: right;
			font-size: 12px;
			color: #999999;
			margin-top: -25px; } }

	.pagination {
		.btn-arrow-left {
			margin: 0 30px 0 0; }
		.btn-arrow-right {
			margin: 0 0 0 30px; }
		.pagination-items li {
            	margin: 0 3px 0 3px; } } }


@media all and (max-width: $md) {
	#bootstrapoverride .exhibition-schedule-container {
		.exhibition-container {
			margin-bottom: 20px;

			& span.fa-map-marker {
				margin-right: 12px; }

			h3	span {
				font-size: 14px;
				line-height: 21px; } } } }


@media all and (max-width: $sm) {
	#bootstrapoverride .exhibition-schedule-container {
		.map-container {
			height: 380px;
			margin-bottom: 20px; }

		.search-controls-expand {
			margin-bottom: 0; }

		.search-controls-wrapper {
			height: 340px;

			&.folded {
				height: 0; }

			.search-wrapper {
				width: 100%; }

			.datepick-wrapper {
				width: 100%;
				margin: 15px auto; } }


		.exhibition-container {
			width: calc(100% + 40px);
			margin-bottom: 10px;
			padding: 20px;
			margin-left: -20px;

			.row {
				display: block;
				margin-bottom: 0;

				.col-md-3 {
					display: none; } }

			.date {
				margin-bottom: 2px;

				.text-wrap {
					font-size: 12px;
					line-height: 17px; } }

			.exhibition-title {
				margin-bottom: 10px; }

			.show-on-map br,
			.exhibition-title:after,
			.products,
			.address {
				display: none; } } } }








