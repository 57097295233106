
// reset
#bootstrapoverride {
	html, body, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p,
	blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em,
	img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u,
	i, center, dl, dt, dd, ul, li, fieldset, form, label, legend, table, caption,
	tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption,
	footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
		margin: 0;
		padding: 0;
		border: 0; } }

#bootstrapoverride {
	h1, h2, h3, h4, h5, li, a, label, p, span, div {
		font-family: $primary-style;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		line-height: 22px;
		color: $text-gray; }


	text {
		font-family: $primary-style; }


	li, a, label, p, span, div {
		font-size: 14px; }


	h1 {
		font-size: 45px; }
	h2 {
		font-size: 37px;
		line-height: 45px; }
	h3 {
		font-size: 24px;
		line-height: 32px; }
	h4 {
		font-size: 20px;
		line-height: 28px; }
	h5 {
		font-size: 15px; }


	p {
		font-size: 15px;
		line-height: 24px;
		color: $text-gray;
		font-weight: normal; }


	ul {
		list-style: none; }


	input, select, label {
		color: $text-gray; }

	a,
	a:link,
	a:visited,
	a:hover,
	a:active {
		// color: inherit
		text-decoration: inherit;
		font-weight: inherit; }

	input:focus,
	textarea:focus {
		outline: none; }


	.body-wrapper {
		display: flex;
		flex-direction: column;
		padding-top: 240px;
		min-height: 100vh;
		background-color: #F8F8F8; }


	.bg-wrapper {
		background-color: white; }


	.clearfix:after {
		content: "";
		clear: both;
		display: table; }


	.fa {
		font-family: "fa", 'Glyphicons Halflings'; }




	.content-container {
		width: 100%;
		margin: 0 auto;
		padding: 60px 20px 80px; } }


@media all and (max-width: 1120px) {
	#bootstrapoverride .body-wrapper {
		padding-top: 180px; } }



@media all and (max-width: 720px) {
	#bootstrapoverride .body-wrapper {
		padding-top: 65px; } }


@media all and (max-width: $sm) {
	#bootstrapoverride {
		.content-container {
			padding: 30px 20px 60px; } } }



