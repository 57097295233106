.album-img-box {
	width: 95%;
}
.album-img-box img{
	width: 100%;
	height: auto;
}
.backend-container {
	max-width: 1180px;
	margin: 0 auto;
	padding: 10px 0;
}
.backend-container tbody .btn {
	max-width: 70px;
    max-height: 28px;
}
.backend-container tbody form .btn {
    padding-top: 3px;
}
.news-title {
	max-height: 23px;
	overflow: hidden;
}