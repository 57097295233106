#bootstrapoverride .search-container {
  max-width: 856px;
  text-align: justify;

  h1.page-title {
    text-align: left;
    padding-left: 35px; }

  .result-row {
    display: flex;
    padding: 20px 35px;
    margin-bottom: 40px;
    transition: background-color .2s;
    flex-direction: column;

    &:hover {
      background-color: white; }

    p, p b {
      font-size: 16px;
      line-height: 1.5; }

    p {
      color: #505050; }

    p b {
      color: $theme-orange; }

    .result-title, .result-title b {
      position: relative;
      margin-bottom: 15px;
      overflow: visible;
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal; }

    .result-title {
      color: #505050;

      &:after {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 0;
        width: 45px;
        height: 1px;
        background-color: $theme-orange; } }

    .result-title b {
      color: $theme-orange; } } }
