// components
#bootstrapoverride {
	.page-name {
		display: none;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 65px;
		padding: 20px 0;
		background-color: white;
		color: #505050;
		font-size: 18px;
		font-weight: bold;
		line-height: 25px;
		text-align: center;
		border-bottom: 5px solid $theme-blue;
		z-index: 1000; }


	.img-wrapper {
		width: 100%;

		img {
			width: 100%;
			object-fit: cover; } }


	.btn {
		position: relative;
		text-align: center;
		display: inline-block; }

	.btn-1 {
		position: relative;
		width: 154px;
		height: 45px;
		background-color: $theme-blue;
		color: white;
		font-size: 16px;
		border-radius: 0;
		padding: 10px 30px 10px 10px;
		transition: background-color .2s;

		&:hover {
			background-color: $theme-orange; } }

	.btn-2 {
		width: 120px;
		color: #027eba;
		font-family: $secondary-style;
		font-size: 15px;
		padding: 5px 0;
		margin: 0 auto;
		background-color: white;
		border-radius: 3px;
		border: 1px solid #027eba;
		transition: all .2s;

		&:hover {
			background-color: $theme-blue;
			color: white; } }

	.button-center {
		text-align: center; }

	h1.page-title {
		font-size: 27px;
		line-height: 38px;
		font-weight: bold;
		color: #027eba;
		text-align: center;
		margin-bottom: 25px; }

	.pagination-wrap-for-ie {
		width: 360px;
		margin: 0px auto;
		display: block; }
	.pagination {
		display: table;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 320px;
		margin: 60px auto 20px;

		&.visible {
			li {
				display: inline-block;
				min-width: 20px; } }

		ul {
			display: table;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			text-align: center; }

		li {
			display: none;
			margin: 0 auto;
			font-size: 18px;
			font-family: $secondary-style;
			cursor: pointer;

			&.active {
				font-weight: bold; }


			&.visible {
				display: inline-block;
				pointer-events: auto; }


			&.ellipsis {
				display: inline-block; }

			a {
				display: block;
				background-color: transparent; } }

		.btn-arrow-left {
			margin: 0 50px 0 0;
			flex-shrink: 0;
			float: left;

			a {
				height: 34px; } }

		.btn-arrow-right {
			margin: 0 0 0 50px;
			flex-shrink: 0;
			float: right;

			a {
				height: 34px; } } }

	.btn-arrow-left,
	.btn-arrow-right {
		position: relative;
		width: 34px;
		height: 34px;
		border-radius: 50%;
		background-color: #027eba;
		box-shadow: $box-shadow2;
		transition: background-color .2s;

		&:hover {
			background-color: $theme-orange; } }

	.btn-arrow-left {
		margin-right: 55px; }
	.btn-arrow-right {
		margin-left: 55px; }

	.btn-arrow-left:after {
		content: url("../../../assets/img/icons/arrow-right.svg");
		position: absolute;
		top: calc(50% - 11px);
		left: calc(50% - 8px);
		width: 15px;
		height: 21px;
		transform: rotate(180deg); }

	.btn-arrow-right:after {
		content: url("../../../assets/img/icons/arrow-right.svg");
		position: absolute;
		top: calc(50% - 10px);
		right: calc(50% - 8px);
		width: 15px;
		height: 21px; }


	.dropdown-wrapper {
		position: relative;
		width: 190px;
		height: 35px;
		margin: 0 auto 10px;

		//arrow
		&:after {
			content: "";
			width: 0;
			height: 0;
			position: absolute;
			right: 15px;
			top: 22px;
			margin-top: -6px;
			border-width: 6px 6px 0 6px;
			border-style: solid;
			border-color: #989898 transparent; } }

	select,
	input[type="text"] {
		width: 100%;
		height: 35px;
		padding: 5px 9px;
		border: solid 1px #e1e1e1;
		border-radius: 2px;
		appearance: none;
		background-color: white;
		cursor: pointer;
		outline: none;
		font-size: 14px;
		color: #777777; }

	.select-wrap {
		display: table;
		overflow: hidden;
		width: 100%;

		select {
			width: 113%;

			&:active, &:focus {
				width: 100%; } } }

	select::-ms-expand {
		display: none!important;
		opacity: 0!important;
		visibility: hidden!important; }



	.search-controls-wrapper {
		display: flex;
		width: 100%;
		height: auto;
		padding: 0;
		margin: 0 auto 25px;

		&>div {
			position: relative;
			display: inline-block;
			margin-right: 20px;

			.container-label {
				position: absolute;
				top: -25px;
				left: 0;
				color: #141414;
				font-weight: normal; } }

		.search-wrapper {
			width: 230px;
			height: 35px; }

		.controls-search-btn {
			float: right;
			width: 100px;
			height: 35px;
			background-color: $theme-blue;
			color: white;
			border: none;
			appearance: none;
			border-radius: 2px;

			&:active,
			&:focus {
				background-color: $theme-orange; } } }

	.datepick-wrapper .fa {
		color: #989898; }

	.daterangepicker_input input { // return default bootstrap style for input in date range picker
		padding: 0 6px 0 28px !important; }

	.daterangepicker_input i {
		top: 10px;
		color: #989898; }


	// multiple select dropdown
	.multiple-select-dropdown {
		position: relative;
		width: 190px;

		&:after {
			content: "";
			width: 0;
			height: 0;
			position: absolute;
			right: 15px;
			top: 22px;
			margin-top: -6px;
			border-width: 6px 6px 0 6px;
			border-style: solid;
			border-color: #989898 transparent;
			pointer-events: none; }


		.col-lg-12 {
			padding: 0; }

		button {
			width: 100%;
			height: 35px;
			padding: 0;
			border: solid 1px #e1e1e1;
			border-radius: 2px;
			appearance: none;
			background-color: white;
			cursor: pointer;
			outline: none;
			font-size: 14px;
			color: #777777;
			text-align: left;

			span {
				display: inline-block;
				width: calc(100% - 32px);
				height: 35px;
				padding: 5px 9px;
				overflow: hidden;
				opacity: .5; } }


		.dropdown-menu {
			width: 420px;
			padding: 20px 20px 0 0;
			border: solid 1px #cccccc;
			box-shadow: none;

			li {
				float: left;
				width: 160px;
				margin-left: 20px;
				margin-bottom: 20px;

				a {
					display: flex;
					align-items: center;
					font-size: 18px;
					line-height: 23px;
					font-weight: normal;
					white-space: normal;

					input {
						display: none; }

					label {
						flex-shrink: 0;
						position: relative;
						cursor: pointer;
						display: inline-block;
						width: 19px;
						height: 19px;
						padding: 0;
						border: 1px solid #cccccc;
						border-radius: 3px;
						margin-right: .8em;
						margin-top: 2px; } } }


			&.full-width-li li {
				width: 100%; }


			input:checked + label {
				background-color: $theme-orange;
				border-color: $theme-orange; }

			input:checked + label:after {
				content: "\f00c";
				font-family: "fa";
				position: absolute;
				top: 0;
				left: 2px;
				color: white;
				line-height: 19px; } } }


	.search-controls-expand {
		display: none;
		position: relative;
		width: 100%;
		height: 50px;
		box-shadow: 0 2px 4px 0 rgba(211,211,211,.5);
		margin-bottom: 10px;
		border: none;
		background-color: white;

		&:after {
			content: "";
			width: 0;
			height: 0;
			position: absolute;
			right: 15px;
			top: 28px;
			margin-top: -6px;
			border-width: 8px 8px 0 8px;
			border-style: solid;
			border-color: #989898 transparent;
			pointer-events: none; } }



	.carousel-container {
		margin-bottom: 30px;

		.img-carousel {
			display: none;

			&.slick-initialized {
				display: block; }

			.img-wrapper {
				width: 100%;

				img {
					height: 400px; } } }


		.btn-arrow-right,
		.btn-arrow-left {
			position: absolute;
			top: calc(50% - 17px);
			z-index: 10; }

		.btn-arrow-left {
			left: -20px; }
		.btn-arrow-right {
			right: -20px; } }


	.video-container {
		height: 400px;
		margin-bottom: 50px; }



	.news-quick-nav {
		max-width: 800px;
		margin: 60px auto 0;

		.prev-news-wrapper {
			float: left;

			&:hover .btn-arrow-left {
				background-color: $theme-orange; } }

		.next-news-wrapper {
			float: right;

			&:hover .btn-arrow-right {
				background-color: $theme-orange; } }

		.news-preview {
			padding: 0;
			display: flex;
			align-items: center;

			&>div {
				display: inline-block; }

			.title {
				margin-bottom: 0;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden; } }

		.news-preview h3 {
			display: inline-block;
			width: 50px;
			color: #027eba;
			font-size: 18px;
			line-height: 24px;
			padding: 0;
			text-align: center; }

		p {
			display: inline-block;
			max-width: 200px;
			margin-bottom: 0; }

		p:after {
			display: none; }

		.btn-arrow-left {
			margin-right: 0; }

		.btn-arrow-right {
			margin-left: 10px; } } }



@media all and (max-width: $lg) {
	#bootstrapoverride {
		.news-quick-nav {
			margin: 30px auto 40px;
			padding: 0 20px;

			.news-preview {
				display: flex;
				align-items: center;

				p {
					max-width: 200px;
					margin-bottom: 0; }

				p:after {
					display: none; } }

			.prev-news-wrapper {
				margin-bottom: 30px; }

			.news-row h3 {
				width: 60px;
				font-size: 18px;
				line-height: 24px;
				padding: 0; }

			.btn-arrow-left {
				margin-right: 0; }

			.btn-arrow-right {
				margin-left: 10px; } } } }



@media all and (max-width: $md) {
	#bootstrapoverride {
		.page-name {
			display: block; }

		.page-title {
			display: none;
			font-size: 20px; }

		.btn-2 {
			font-weight: 600;
			letter-spacing: 1px; }

		.search-controls-wrapper {
			padding-left: 15px;
			padding-right: 15px;

			.dropdown-wrapper {
				width: 224px; }

			.controls-search-btn {
				width: 226px; } } } }


@media all and (max-width: $sm) {
	#bootstrapoverride {
		.search-controls-expand {
			display: block; }

		.search-controls-wrapper {
			display: block;
			height: 270px;
			padding: 0 12px;
			overflow: hidden;
			background: white;
			transition: height .3s;

			&.folded {
				height: 0; }

			&.overflow-visible {
				overflow: visible; }


			&>div:first-child {
				margin-top: 40px; }

			.dropdown-wrapper {
				width: 100%;
				margin: 15px auto; }

			.datepick-wrapper {
				width: 100%;
				margin: 15px auto; }

			.search-wrapper {
				width: 100%;
				margin: 15px auto; }

			.controls-search-btn {
				width: 100%; }


			.multiple-select-dropdown, {
				position: relative;
				width: 100%;
				margin: 15px auto;

				.dropdown-menu {
					width: 100%; } } } } }







