#bootstrapoverride .taiwan-list-container {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	.exhibition-container {
		width: 500px;
		display: inline-block;
		box-shadow: inset 0px -8px 0px #68CC59;

		.taiwan-exhibition-title {
			margin-bottom: 16px;
			&:after {
				content: none; } }

		.text-wrap {
			color: #141414; }

		hr {
			margin: 8px 0;
			margin-left: 0;
			border: 0;
			width: 40px;
			height: 1px;
			background-color: $theme-orange; }

		.products {
			margin-top: 8px; }

		.agent-wapper {
			margin: 24px 0;
			padding: 12px;
			background-color: rgba(101, 179, 89, 0.05);

			.tel {
				color: #777; } }

		.btn-2 {
			margin-bottom: 8px; } } }

@media all and (max-width: $xl) {
	#bootstrapoverride .taiwan-list-container {
		.exhibition-container {
			width: 477px; } } }

@media all and (max-width: $lg) {
	#bootstrapoverride .taiwan-list-container {
		.exhibition-container {
			width: 728px; } } }


