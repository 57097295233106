#bootstrapoverride .member-directory-container {
	max-width: 900px;
	text-align: justify;


	.page-title {
		margin-bottom: 60px; }


	.search-controls-wrapper {
		max-width: 780px; }

	.text-wrapper {
		margin: auto;
		max-width: 780px; }


	.member-detail-row {
		display: flex;
		align-items: center;
		padding: 10px 40px;
		margin-bottom: 20px;
		background-color: white;

		.member-info {
			display: table-footer-group; // for edge
			display: flow-root; }

		h2 {
			font-weight: bold;
			line-height: 25px;

			&.chinese-name {
				font-size: 18px;
				color: #027eba; }

			&.english-name {
				font-size: 14px;
				color: #141414;
				margin-bottom: 18px; } }

		a {
			color: #027eba;
			text-decoration: underline; }


		.img-wrapper {
			flex-shrink: 0;
			width: 120px;
			height: 120px;
			margin-right: 60px;
			float: left;

			img {
				height: 120px; } } } }


#bootstrapoverride .apply-membership-container,
#bootstrapoverride .change-membership-container {
	width: 100%;
	max-width: 750px;
	margin: 0 auto;

	h1.page-title {
		margin-bottom: 60px; }

	h2 {
		font-weight: bold;
		font-size: 16px;
		line-height: 28px;
		margin-bottom: 20px; }

	p {
		line-height: 28px;

		a {
			color: #027eba;
			text-decoration: underline; } }

	.list-points {
		display: inline-block;
		width: 100px; }

	.flex {
		display: flex;

		span {
			flex-shrink: 0; } }

	.content {
		margin-bottom: 50px; }

	.documents {
		margin-bottom: 30px;

		a {
			color: #027eba;
			text-decoration: underline; }

		img {
			vertical-align: middle;
			margin-left: 8px; } }

	.supporting {
		margin-bottom: 30px;

		h2 {
			font-size: 15px;
			margin-bottom: 0px; }

		ul {
			list-style: initial;
			padding-left: 20px; } } }


@media all and (max-width: $sm) {
	#bootstrapoverride .member-directory-container {
		.member-detail-row {
			display: block;
			width: calc(100% + 40px);
			margin-left: -20px;
			padding: 25px;

			h2 {
				line-height: 21px;

				&.chinese-name {
					font-size: 14px; }

				&.english-name {
					font-size: 12px; } }


			.address {
				line-height: 19px; }

			.website {
				display: none; }

			.img-wrapper {
				margin: 0 auto 20px; } } } }















