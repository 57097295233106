#bootstrapoverride .newsletter-container {
	position: relative;
	max-width: 860px;

	iframe {
		overflow-x: hidden;
		width: 820px;
		height: 100vh; }


	.newsletter-inner-wrapper {
		padding: 30px 60px;
		background-color: white;


		.carousel-container {
			margin-bottom: 30px; }

		.newsletter-img-carousel {
			display: none;

			&.slick-initialized {
				display: block; } }

		.btn-arrow-right,
		.btn-arrow-left {
			position: absolute;
			top: calc(50% - 17px);
			z-index: 10; }

		.btn-arrow-left {
			left: -20px; }
		.btn-arrow-right {
			right: -20px; }

		.img-wrapper {
			width: 100%;

			img {
				height: 400px; } }


		.video-container {
			height: 400px;
			margin-bottom: 50px; } }


	.heading {
		position: relative;
		width: 100%;
		margin-bottom: 40px;

		.newsletter-date {
			font-size: 18px;
			line-height: 25px;
			font-weight: bold;
			text-align: center;
			margin-bottom: 5px; }

		.time-period {
			text-align: center; }

		.date {
			position: absolute;
			top: 28px;
			right: 0; } }

	.local-nav {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-bottom: 25px;

		li {
			font-size: 18px;
			font-weight: bold;
			cursor: pointer;
			transition: color .2s;

			&:hover {
				color: $theme-orange; } } }


	section {
		margin-bottom: 30px;

		h2 {
			font-size: 14px;
			font-weight: bold;
			line-height: 20px;
			padding: 5px 10px;
			background-color: #eff3f5;
			margin-bottom: 10px; } }

	p {
		margin-bottom: 15px; }

	.img-wrapper {
		width: 100%;
		margin-bottom: 18px;

		img {
			height: 210px; } }

	// mobile quick nav styling
	.mobile-expandable-menu {
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: 280px;
		background-color: white;
		box-shadow: $box-shadow;
		overflow: scroll;
		z-index: 10;
		transition: height .3s;

		&:after {
			content: "";
			width: 0;
			height: 0;
			position: absolute;
			top: 20px;
			right: 30px;
			border-width: 8px 8px 0 8px;
			border-style: solid;
			border-color: #797979 transparent; }

		&.collapsed {
			height: 50px;
			overflow: hidden; }

		.active-item {
			font-size: 18px;
			color: #141414;
			font-weight: bold;
			padding: 15px 20px;
			margin-bottom: 20px; }

		ul {
			padding: 0 30px;

			li {
				font-size: 16px;
				margin-bottom: 15px; } } }

	.search-controls-wrapper {
		padding: 0 75px;

		.dropdown-wrapper {
			width: 260px; } } }





@media all and (max-width: $lg) {
	#bootstrapoverride .newsletter-container {
		.heading {
			.time-period {
				margin-bottom: 0; }

			.date {
				position: static;
				text-align: center; } }

		.search-controls-wrapper {
			padding: 0 30px; } } }


@media all and (max-width: $sm) {
	#bootstrapoverride .newsletter-container {
		padding: 90px 20px 80px;

		.heading {
			margin-bottom: 20px; }

		.local-nav {
			display: none; }

		.mobile-expandable-menu {
			display: block; }


		.newsletter-section {
			display: none;

			&.shown {
				display: block; } } } }


@media all and (max-width: 770px) {
	#bootstrapoverride .newsletter-container {
		iframe {
			width: 100%; }

		.newsletter-inner-wrapper ul li:hover {
			color: $theme-orange; }

		.search-controls-wrapper {
			.dropdown-wrapper {
				width: 100%; } } } }





