#bootstrapoverride .government-agencies-container {
	max-width: 750px;

	h1 {
		font-size: 18px;
		line-height: 25px;
		font-weight: bold;
		margin-bottom: 30px; }

	.img-wrapper {
		width: 170px;

		img {
			height: 65px; } }

	.row {
		margin-bottom: 30px; } }


#bootstrapoverride .exhibitions-container {
	max-width: 1200px;

	h1 {
		font-size: 18px;
		line-height: 25px;
		font-weight: bold;
		margin-bottom: 50px;
		margin-left: -10px; }

	h2 {
		font-size: 18px;
		line-height: 25px;
		margin-bottom: 35px; }


	.img-wrapper {
		width: 170px;

		img {
			height: 65px; } }

	div[class^="col"] {
		margin-bottom: 30px; }

	.row {
		margin-bottom: 110px; } }


.row {
	.link-height {
		height: 150px; } }

@media all and (max-width: 770px) {
	#bootstrapoverride {
		.government-agencies-container .row div {
			margin-top: 30px;

			&:first-child {
				margin-top: 0; }

			&:nth-of-type(2) {
				margin-top: 0; } }

		.exhibitions-container {
			.taiwanrow {
				margin-bottom: 90px;
 } } }				//height: 160px

	.row {
		.link-height {
			height: inherit; } } }
