#bootstrapoverride .home-container {
	width: 100%;
	.hero-about {
				font-size: 15px;
				line-height: 24px;
				color: $text-gray;
				font-weight: normal;
				text-align: justify; }
	.hero {
		position: relative;
		width: 100%;
		height: 500px;
		background-color: #f7f8f8;

		.mobile-hero-logo {
			display: none;
			position: absolute;
			top: 40px;
			left: 0;
			right: 0;
			margin: 0 auto; }

		.mobile-title {
			display: none; }

		.hero-bg {
			@include image-formating("../../../assets/img/hero_pure.png");
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 500px;
			box-shadow: $inset-shadow; }

		.hero-slogan {
			position: absolute;
			top: 130px;
			right: 70px;
			width: 490px;

			p {
				color: white;
				text-shadow: $text-shadow;
				letter-spacing: 1px;
				text-indent: 30px; }

			.btn {
				position: absolute;
				bottom: -65px;
				right: 0;

				.arrow-right {
					position: absolute;
					top: 4px;
					right: 10px; } }

			.btn-download {
				right: 180px;
				&:after {
					content: url('../../../assets/img/icons/download.svg');
					position: absolute;
					right: 20px;
					width: 25px;
					margin-top: -2px; } }

			.btn-ebook {
				&:after {
					content: url('../../../assets/img/icons/btn-ebook.svg');
					position: absolute;
					right: 20px;
					width: 25px;
					margin-top: 2px; } } } }

	.inner-container {
		position: relative;
		width: 100%;
		max-width: 1100px;
		margin: 0 auto; }


	section h2 {
		color: #027eba;
		font-size: 27px;
		font-weight: bold;
		text-align: center;
		margin-bottom: 45px; }


	.news-section {
		padding: 85px 0 60px;
		background-color: white;

		.news-left {
			float: left;
			width: 50%; }

		.news-right {
			float: right;
			width: 50%; }

		.news-left {
			border-right: 1px solid #979797; }

		.news-row {
			display: flex;
			padding: 0 40px;

			&:hover h3 {
				color: $theme-orange; }

			.news-content {
				min-width: calc(100% - 110px); }

			h3 {
				flex-shrink: 0;
				float: left;
				display: block;
				margin: 0px auto;
				width: 110px;
				color: #027eba;
				font-size: 36px;
				font-family: $secondary-style;
				line-height: 39px;
				padding-right: 30px;
				text-align: center;
				transition: color .2s; }

			p {
				font-size: 16px; }

			.news-title-wrap {
				display: table;
				position: relative;
				margin-bottom: 15px;

				&:after {
					content: "";
					position: absolute;
					bottom: -8px;
					left: 0;
					width: 40px;
					height: 2px;
					background-color: $theme-orange; }

				&>span {
					display: inline-block;
					max-height: 48px;
					overflow: hidden;
					font-weight: bold;
					font-size: 16px; } }

			.news-preview {
				max-height: 73px;
				overflow: hidden;
				word-break: normal; }

			&.margin-bottom {
				margin-bottom: 60px; } }

		.button-center {
			margin-top: 45px; } }


	.carousel-section {
		background-color: #f7f8f8;
		padding: 40px 0;

		.inner-container {
			max-width: 1450px; }

		.event-wrapper {
			width: 345px;
			height: 365px;
			background-color: white;
			margin: 0 10px 10px 0;

			.img-wrapper img {
				height: 255px; }

			&:hover h5 {
				color: $theme-orange; } }

		.btn-arrow-left,
		.btn-arrow-right {
			position: absolute;
			top: 290px;
			z-index: 1;

			&:hover {
				background-color: $theme-orange; } }

		.btn-arrow-right {
			right: 30px; }

		.btn-arrow-left {
			left: 30px; }

		.event-info-wrapper {
			padding: 20px 24px; }


		h5 {
			color: #027eba;
			font-family: $secondary-style;
			font-size: 16px;
			font-weight: bold;
			margin-bottom: 5px;
			transition: color .3s; }

		p {
			font-size: 16px;
			max-height: 48px;
			overflow: hidden; }

		.btn {
			margin-top: 35px; } }


	.partners-section {
		padding: 50px 0;
		background-color: white;
		overflow: hidden;

		.inner-container {
			max-width: 1450px;
			height: auto; }

		.img-wrapper {
			width: 310px;
			margin: 20px 15px;
			background-color: gray;

			img {
				height: 140px; } } } }


@media all and (max-width: 1120px) {
	#bootstrapoverride .home-container {
		.hero {
			height: 300px;

			.hero-bg {
				height: 300px; }

			.hero-slogan {
				position: absolute;
				top: 70px;
				right: 90px;
				padding-left: 30px;

				p.en {
					line-height: 20px; }

				.btn {
					position: absolute;
					bottom: -50px; } } }


		section h2 {
			font-size: 20px;
			margin-bottom: 30px; }


		.news-section {
			padding: 45px 0 30px;

			.news-row {
				padding: 0 20px;

				h3 {
					font-size: 24px;
					line-height: 28px;
					width: 90px; }

				.news-content {
					//min-width: 100px
					min-width: calc(100% - 90px); }

				&.margin-bottom {
					margin-bottom: 30px; } }

			.button-center {
				margin-top: 30px; } } } }




@media all and (max-width: $sm) {
	#bootstrapoverride .home-container {
		.hero {
			height: auto;

			.mobile-hero-logo {
				display: block;
				top: 70px; }

			.hero-bg {
				height: 185px; }

			.inner-container {
				max-width: 500px;
				padding: 210px 20px 20px; }

			.hero-slogan {
				position: static;
				width: 100%;
				text-align: justify;
				padding: 0;

				p {
					color: $text-gray;
					text-shadow: none; }

				.btn {
					position: static;
					display: block;
					width: 100%;
					height: auto;
					margin: 20px auto 0;
					color: #027eba;
					font-size: 15px;
					padding: 5px 0;
					background-color: white;
					border-radius: 3px;
					border: 1px solid #027eba;
					line-height: 22px;

					&:before,
					&:after {
						display: none; } } }


			.mobile-title {
				display: block;
				font-size: 18px;
				line-height: 25px;
				font-weight: bold;
				color: #027eba;
				text-align: center;
				margin-bottom: 15px; } }


		.news-section {
			padding: 30px 0;

			.inner-container {
				max-width: 500px; }

			.news-left {
				float: none;
				width: 100%; }

			.news-right {
				float: none;
				width: 100%; }

			.news-left {
				border-right: none; }

			.news-row {
				padding: 0 20px;
				margin-bottom: 20px;
				display: inline-flex;

				.news-content {
					min-width: calc(100% - 60px); }

				h3 {
					width: 60px;
					padding-right: 15px;
					font-size: 20px;
					line-height: 22px; }

				.news-title {
					position: relative;
					font-weight: bold;
					margin-bottom: 15px;

					&:after {
						display: none; } }

				.news-preview {
					display: none; }

				&.margin-bottom {
					margin-bottom: 20px; } }

			.button-center {
				margin-top: 0;
				padding: 0 20px;

				.btn {
					width: 100%; } } }


		.carousel-section {
			.button-center {
				max-width: 500px;
				margin: 0 auto;
				padding: 0 20px;

				.btn {
					width: 100%; } }

			.btn-arrow-right {
				top: calc(50% - 17px);
				right: 15px; }
			.btn-arrow-left {
				top: calc(50% - 17px);
				left: 15px; } }


		.partners-section {
			padding: 20px 0; }

		.partner-carousel .slick-slide {
			margin: 0 -10px; } } }





