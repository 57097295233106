#bootstrapoverride .news-container {
	max-width: 950px;
	text-align: justify;

	.page-title {
		margin-bottom: 60px; }

	.news-row {
		display: flex;
		align-items: center;
		padding: 20px 40px;
		margin-bottom: 40px;
		transition: background-color .2s;

		&:hover {
			background-color: white; }

		&:hover h3 {
			color: $theme-orange; }

		h3 {
			flex-shrink: 0;
			float: left;
			display: block;
			margin: 20px auto;
			width: 110px;
			color: #027eba;
			font-weight: bold;
			font-size: 28px;
			padding-right: 15px;
			text-align: center;
			transition: color .2s; }

		p {
			font-size: 16px; }

		.news-content {
			text-align: justify; }

		.news-title {
			position: relative;
			font-weight: bold;
			margin-bottom: 15px;
			overflow: visible;
			display: table;

			&:after {
				content: "";
				position: absolute;
				bottom: -8px;
				left: 0;
				width: 40px;
				height: 2px;
				background-color: $theme-orange; } }

		.news-preview {
			max-height: 73px;
			overflow: hidden; } } }



#bootstrapoverride .news-detail-container {
	.news-detail-inner-wrapper {
		background-color: white;
		padding: 35px 60px;

		p a {
			color: $link-color;
			text-decoration: underline; } }


	.news-detail-title {
		font-size: 18px;
		font-weight: bold;
		text-align: center;
		margin-bottom: 30px; }

	.date {
		font-weight: bold;
		text-align: right;
		margin-bottom: 30px; }


	.img-wrapper {
		width: 370px;
		margin: 25px auto 45px;

		img {
			height: 210px; } } }




@media all and (max-width: $lg) {
	#bootstrapoverride .news-container {
		background-color: white;

		.news-row {
			padding: 0;

			h3 {
				width: 80px;
				padding-right: 20px; }

			p {
				font-size: 16px; }

			.news-content {
				text-align: justify; }

			.news-title {
				position: relative;
				font-weight: bold;
				margin-bottom: 15px;

				&:after {
					content: "";
					position: absolute;
					bottom: -8px;
					left: 0;
					width: 40px;
					height: 2px;
					background-color: $theme-orange; } } } }


	#bootstrapoverride .news-detail-container {
		padding: 10px 0;

		.news-detail-inner-wrapper {
			background-color: white;
			padding: 20px; }


		.img-wrapper {
			width: 100%;
			margin: 25px auto 45px;

			img {
				height: 210px; } }


		.news-row {
			margin-bottom: 30px;

			h3 {
				width: 50px;
				font-size: 18px;
				line-height: 22px; }

			.news-title {
				margin-bottom: 0;

				&:after {
					display: none; } } } } }






@media all and (max-width: $sm) {
	#bootstrapoverride .news-container {
		.news-row {
			.news-content {
				position: relative;

				&:after {
					content: "";
					position: absolute;
					bottom: 5px;
					left: 0;
					width: 40px;
					height: 2px;
					background-color: $theme-orange; } }

			.news-title {
				margin-bottom: 15px;
				max-height: 48px;
				overflow: hidden; }

			.news-preview {
				display: none; }

			h3 {
				width: 60px;
				font-size: 20px;
				line-height: 22px;
				font-weight: normal; } } } }










