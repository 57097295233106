.body-wrapper {
    background-color: #f7f7f7; }

#fecs_container {
    width: 1000px;
    max-height: 1713px;
    margin: 0 auto;

    #fecs_title {
        width: 940px;
        height: 143px;
        margin: 0 auto;
        display: table-cell;
        vertical-align: middle;
        // display: flex
        // align-items: center
        // justify-content: center

        #category_title {
            margin: 0 auto;
            width: 195px;
            height: 38px;
            font-size: 27px;
            font-weight: 500;
            line-height: 38px;
            letter-spacing: 1px;
            text-align: center;
            color: #027eba; } }

    .search_box {
        width: 940px;
        height: 254px;
        margin: 0 auto;
        padding-top: 30px;
        background-color: #ffffff; }

    .search_box .search_list {
        li {
            width: 100%; }

        .category_list {
            text-transform: capitalize; }

        .category_list li {
            position: relative;
            display: inline;
            height: 20px;
            margin-right: 0;
            text-align: left;

            .category_span {
                display: inline-block;
                width: 90px;
                font-size: 12px;
                height: 20px; }

            span:last-child {
                margin-left: 30px; }


            input {
                position: absolute;
                background-color: #e5735c;
                display: inline-block;
                width: 20px;
                height: 20px;
                opacity: 0;
                z-index: 100;
                margin-top: 0;
                left: 0;

                &:checked ~.checkmark {
                        background-color: #e5735c;

                        &:after {
                            display: block; } } }


            .checkmark {
                position: absolute;
                border: solid 1px #cccccc;
                top: 0;
                left: 0;
                height: 20px;
                width: 20px;
                border-radius: 4px;
                background-color: white;

                &:after {
                    content: "";
                    position: absolute;
                    display: none;
                    left: 5.4px;
                    top: 2px;
                    width: 7px;
                    height: 12px;
                    border: solid white;
                    border-radius: 3px;
                    border-width: 0 3px 3px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg); } } }

        .search_ul {
            margin-bottom: 20px;

            select {
                width: 360px;
                height: 37px; }

            .search_input, .search_btn {
                width: 100%;
                height: 37px; }

            .search_item {
                position: relative;
                width: 85%; }

            .search_label {
                width: 13%; }

            .dropdown-wrapper {
                margin: 0; }

            .search_item .search_btn {
                display: inline-block;
                background-color: #0b5f88;
                color: #ffffff;
                text-align: center;
                font-family: inherit;
                font-size: inherit;
                border-radius: 2px;
                line-height: 37px;

                &:active, &:focus {
                    background-color: #e5735c; } }


            .search_item li[name="country"] {
                    width: 360px; } }

        .search_label, .search_item {
            display: inline-block; }

        .search_label {
            width: 100px;
            padding-left: 12px;

            label {
                font-size: 13px;
                padding-left: 12px; } } }

    #fecs_content_wapper {
        width: 940px;
        margin: 20px auto;

        .fec_content {
            width: 459px;
            height: 143px;
            padding-top: 8px;
            background-color: #ffffff;
            position: relative;
            margin-bottom: 20px;
            display: inline-block;
            transition-property: box-shadow;
            transition-duration: 0.5s;

            &:hover {
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }

            .content_wapper {
                position: absolute;
                width: 446px;
                right: 0; }

            .fec_footer {
                position: absolute;
                width: 459px;
                height: 16px;
                bottom: 0; }

            .content_category {
                width: 45px;
                height: 18px;
                margin-bottom: 6px;
                color: #ffffff;
                text-align: center;
                line-height: 18px;
                border-radius: 2px; }

            .Exhibition {
                background-color: #0c5f88;
                width: 75px; }

            .Exhibition_zh {
                background-color: #0c5f88; }

            .Convention {
                background-color: #399c52;
                width: 85px; }

            .Convention_zh {
                background-color: #399c52; }

            .Facilities, .Facilities_zh {
                background-color: #e5735c;
                width: 75px; }

            .fec_name {
                width: 426px;
                height: 50px;
                font-size: 18px;
                font-weight: 600;
                line-height: 50px;
                color: #141414;
                overflow: hidden; }

            .fec_line {
                width: 40px;
                height: 2px;
                margin-top: 7px; }

            .Exhibition_line {
                border-bottom: 1px solid #0c5f88; }

            .Convention_line {
                border-bottom: 1px solid #399c52; }

            .Facilities_line {
                border-bottom: 1px solid #e5735c; }

            .fec_location {
                height: 15px;
                width: 160px;
                margin-top: 10px;

                span {
                    line-height: 20px;
                    text-align: center;
                    font-size: 14px;
                    color: #777777; }


                span:first-child {
                    margin-left: 8px;
                    font-weight: 600; }

                span:last-child {
                    margin-left: 10px; } } }

        .fec_content:nth-child(odd) {
            margin-right: 17px; } }

    .pagination li {
            display: inline-block; }

    .pagination li a.active {
            font-weight: bolder; } }

@media all and (max-width: $lg) {
    #fecs_container {
        width: 94%;
        max-height: 2905px;

        .search_box {
            width: 94%; }

        .search_box .search_list {
            .search_label {
                width: 94%;
                padding-left: 12px;

                label {
                    padding-left: 0px; } }

            .category_list {
                li {
                    width: 90%;
                    margin-right: 30px; } }

            .search_ul .search_item {
                .search_input, .search_btn {
                    width: 95%; } } }

        #fecs_content_wapper {
            width: 94%;

            .fec_content {
                width: 100%;

                .content_wapper {
                    width: 94%;

                    .fec_name {
                        width: 100%; } }

                .fec_footer {
                    width: 100%;
                    overflow: hidden;

                    img {
                        width: 100%; } } } }

        .pagination {
            margin-left: auto;
            width: 300px;

            .btn-arrow-left {
                margin-right: 20px; }

            .btn-arrow-right {
                margin-left: 20px; } } } }

@media (max-width: $sm) {
    #fecs_container {
        max-height: 2905px;

        .search_box {
            height: 275px;
            padding-top: 10px; }

        .search_box .search_list .search_ul {
            margin-bottom: 2px;

            .search_label {
                width: 50%;
                display: block;
                padding-left: 6%; }

            .search_item {
                width: 105%;
                padding-left: 6%;
                padding-top: 5px;

                li[name="country"] {
                    width: 90%;

                    select {
                        width: 100%; } }

                .category_list {
                    padding-top: 0px;

                    li {
                        margin-right: 0.4px;
                        width: 25%;

                        span {
 }                            // font-size: 12px

                        span:last-child {
                            width: 24%;
                            margin-left: 8%; } } }

                .search_input {
                    width: 90%; }

                .search_btn {
                    width: 90%; } } }

        #fecs_content_wapper {
            max-height: 2350px;

            .fec_content {
                width: 100%; } } } }






