.book-section {
    padding: 50px 175px;
    background-color: #E0E0E0;
    position: relative;
    .left-arrow {
        position: absolute;
        top: 44%;
        left: 4%; }
    .right-arrow {
        position: absolute;
        top: 44%;
        right: 4%; }
    button {
        all: unset; }
    .zoom-section {
        position: absolute;
        top: 4%;
        right: 4%; } }
.page-indicator-section {
    height: 1em;
    width: 100%;
    display: flex;
    #page-number {
        width: 10%;
        margin-top: .4em;
        text-align: right; }
    #slider {
        width: 90%;
        pointer-events: none; }
    #slider {
        .ui-slider-handle {
            width: 1em;
            height: 1em;
            outline: 0;
            background: #000;
            border-radius: 60%;
            border: 1px solid white;
            top: -.3em;
            cursor: default; } }
    #slider.ui-slider-horizontal {
        top: 1em;
        left: 3%;
        height: .6em;
        width: 90%;
        border: 1px solid white;
        background: #BDBDBD; }

    #slider {
        .ui-slider-range {
            background: #000; } } }
.ebook {
    .page {
        img {
            max-width: 100%;
            height: 100%; } } }

@media (max-width: $lg) {
    .book-section {
        padding: 8% 17%; }

    .book-section {
        .left-arrow {
            display: none; }
        .right-arrow {
            display: none; }

        .zoom-section {
            top: 0;
            right: 0; } } }

@media (max-width: 425px) {
    .book-section {
        padding: 10% 3%; } }
