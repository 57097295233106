// about page
#bootstrapoverride .about-container {
	max-width: 750px;
	text-align: justify;

	.img-wrapper {
		margin-bottom: 20px;
		img {
			height: auto; } }

	h2 {
		position: relative;
		font-size: 16px;
		font-weight: bold;
		margin-bottom: 30px;

		&:after {
			content: "";
			position: absolute;
			bottom: 0px;
			left: 0;
			width: 40px;
			height: 2px;
			background-color: $theme-orange; } }


	section {
		margin-bottom: 30px; }

	p a {
		color: #027eba;
		text-decoration: underline; }


	.map-container {
		width: 100%;
		height: 370px;
		margin-bottom: 25px; }

	.contact-info {
		p {
			margin-bottom: 3px; } }


	.list-points {
		display: inline-block;
		width: 30px; }

	.flex {
		display: flex;

		p {
			margin-bottom: 0; }

		span {
			flex-shrink: 0; } } }


@media (max-width: 770px) {
	#bootstrapoverride .about-container {
		.tabpadding {
			margin-top: 45px; } } }


#bootstrapoverride .board-container {
	max-width: 1100px;

	.page-title {
		margin-bottom: 80px; }

	.inner-wrapper {
		width: 100%;
		height: 360px;
		margin: 0 auto;
		text-align: center;
		padding-bottom: 50px;

		.img-wrapper {
			width: 170px;
			height: 170px;
			overflow: hidden;
			border-radius: 85px;
			margin: 0 auto 20px; }

		h5 {
			color: #027eba;
			font-size: 14px;
			font-weight: bold; }

		h4 {
			color: #027eba;
			font-size: 18px;
			font-weight: bold; } } }




@media all and (max-width: $md) {
	#bootstrapoverride .about-container {
		padding: 30px 20px 60px;

		h2 {
			margin-bottom: 15px; }

		.img-wrapper {
			margin-bottom: 15px;
			width: 70%; }

		.map-container {
			height: 200px;
			margin-bottom: 15px; } }



	#bootstrapoverride .board-container {
		max-width: 400px;

		.inner-wrapper {
			width: 100%;
			height: auto;
			margin: 0 auto;
			text-align: center;
			padding-bottom: 10px;

			.img-wrapper {
				float: left;
				width: 100px;
				height: 100px; }

			.info-wrapper {
				width: calc(100% - 120px);
				float: left;
				text-align: left;
				margin-left: 20px; } } } }
