#bootstrapoverride footer {
	margin-top: auto;
	padding: 80px 0 45px;
	background-color: #383838;

	a, p, li, h3, span {
		color: rgba(255,255,255,.9); }

	.inner-wrapper {
		width: 100%;
		max-width: 1100px;
		margin: 0 auto; }

	.row>div {
		padding-left: 40px;
		height: 280px; }

	.row {
		position: relative; }

	.border-right {
		border-right: 1px solid rgba(255,255,255,.5); }

	h3 {
		margin-bottom: 30px;
		font-size: 22px;
		font-weight: bold;
		color: rgba(255,255,255,.9); }

	p {
		font-size: 14px; }
	span.date {
		font-size: 13px; }

	.news p {
		position: relative;
		margin-bottom: 25px;
		padding-left: 15px;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 2px;
			height: 45px;
			background-color: white; }

		a {
			display: inline-block;
			height: 45px;
			overflow: hidden; } }

	ul li {
		font-size: 18px;
		margin-bottom: 15px; }

	.address {
		margin-bottom: 70px; }

	.copy {
		position: absolute;
		right: 95px;
		bottom: 20px; } }


@media all and (max-width: $xl) {
	#bootstrapoverride footer {
		padding: 50px 50px 45px;

		.border-right {
			border-right: none; }

		.copy {
			position: absolute;
			text-align: center;
			left: 0;
			bottom: 0;
			right: 0; } } }


@media all and (max-width: $md) {
	#bootstrapoverride footer {
		padding: 25px 20px 20px;

		p {
			font-size: 12px; }

		.row>div {
			height: 150px;
			padding-left: 20px; }

		h3 {
			display: none; }

		.copy {
			font-size: 10px;
			opacity: .5; } } }
