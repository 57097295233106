
// colors
$text-gray: #232323;
$text-black: #141414;
$pink: #e0007a;
$purple: #251c99;
$yellow: #fdcc1a;
$theme-blue: #0b5f88;
$theme-orange: #e5735c;
$link-color: #027eba;
.fa-mycheck:before {
	content: "";
	width: 15px;
	height: 12px;
	display: block;
	background-image: url('/assets/img/icons/check.png');
	background-repeat: no-repeat;
	background-size: cover; }
// font stack
@font-face {
	font-family: 'fa';
	src: url('/assets/fonts/Fontawesome.ttf');
	font-weight: normal;
	font-style: normal; }

@font-face {
	font-family: 'Noto';
	src: url('/assets/fonts/NotoSans-Regular.ttf');
	font-weight: normal;
	font-style: normal; }
@font-face {
	font-family: 'Noto';
	src: url('/assets/fonts/NotoSans-Bold.ttf');
	font-weight: bold;
	font-style: normal; }

@font-face {
	font-family: 'Lato';
	src: url('/assets/fonts/Lato-Regular.ttf');
	font-weight: normal;
	font-style: normal; }
@font-face {
	font-family: 'Lato';
	src: url('/assets/fonts/Lato-Bold.ttf');
	font-weight: bold;
	font-style: normal; }


$primary-style:  "PingFang TC", "Heiti TC", "微軟正黑體", "Open Sans", "Lato", "Noto", "Helvetica","AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Arial", "Microsoft JhengHei", sans-serif, "Glyphicons Halflings";
$secondary-style: "Helvetica", "Lato", "Noto","AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "Heiti TC", "微軟正黑體", "Microsoft JhengHei", sans-serif;

// image mixin
@mixin image-formating($image) {
	background-image: url($image);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%; }


// mixins
@mixin gradient-border($border, $directions...) { // "..." mean variable argument, can have just one or more variables provided
	border: $border solid transparent;
	border-image: linear-gradient(to $directions, #a159c9 0%, #3023ae 25%, #3721ac 50%, #e0007a 75%, #e92f64 100%);
	border-image-slice: 1; }




// shadows
$box-shadow: 1px 1px 6px 2px rgba(0,0,0, .2);
$box-shadow2: 0px 1px 2px 1px rgba(0,0,0, .3);
$box-shadow3: 0 2px 4px 0 rgba(211,211,211, .5);
$inset-shadow: inset 0 0 0 1000px rgba(0,0,0,.1); // darkening of pictures
// $text-shadow: 2px 2px 6px rgba(20,27,45,.5)
// $text-shadow: 0 2px 4px rgba(0,0,0,.5)
$text-shadow: 0 3px 2px rgba(0, 0, 0, 0.11);
$text-shadow2: 0 2px 4px rgba(0, 0, 0, 0.5);
$text-shadow3: 0 2px 2px rgba(0, 0, 0, 0.2);


//bootstrap breakpoints
$xl: 1200px;
$lg: 992px;
$md: 767px;
$sm: 576px;
$xs: 0px;







