
#bootstrapoverride .modal-search-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0,0,0,.6);
	z-index: 100;
	transition: opacity .3s;

	&.closed {
			opacity: 0;
			pointer-events: none;
			z-index: -9999; }

	.search-box-wrapper {
		display: flex;
		align-items: center;
		position: absolute;
		top: calc(50vh - 45px);
		left: 0;
		right: 0;
		width: 90%;
		max-width: 1100px;
		height: 90px;
		padding: 20px 30px;
		margin: 0 auto;
		background-color: white;
		border-radius: 2px;

		img {
			width: 34px;
			height: 34px; }

		input {
			width: 80%;
			height: 50px;
			border: none;
			border-bottom: solid 1px #979797;
			border-radius: 0;
			font-size: 22px;
			margin-left: 10px; }

		button {
			width: 120px;
			height: 46px;
			background-color: #007dbc;
			color: white;
			border: none;
			border-radius: 2px;
			margin-left: 20px;
			letter-spacing: 1px;

			&:active,
			&:focus {
				background-color: $theme-orange; } } } }





#bootstrapoverride nav {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 240px;
	background-color: white;
	z-index: 9999;

	&.nav-fixed {
		position: fixed;
		top: -170px; }

	&.nav-fixed-mobile {
		position: fixed;
		top: -140px; }


	.mobile-nav {
		display: none;
		position: fixed;
		top: 0;
		right: 0;
		width: 260px;
		height: 100vh;
		background-color: #383838;
		z-index: 1000;
		border-bottom: none;
		transition: right .5s;

		&.folded {
			right: -260px; }

		li img {
			margin-left: -5px; }

		li {
			margin-bottom: 0;
			padding: 10px 30px;

			a {
				color: white;
				font-weight: bold; } } }

	.folded .bottom-nav {
		right: -260px; }


	.bottom-nav {
		position: fixed;
		bottom: 0;
		right: 0;
		width: 100%;
		width: 260px;
		padding: 10px 20px;
		border-top: solid 1px rgba(0,0,0,.2);
		transition: 0.5s;

		a {
			float: right;
			color: white; }

		ul {
			float: left;

			li {
				display: inline;
				color: white;
				font-family: $secondary-style;
				font-weight: bold;

				a {
					font-weight: normal;
					float: none; } } }

		.lang-en {
			padding: 10px;
			position: relative;
			margin-right: 15px;

			&:after {
				content: "";
				position: absolute;
				top: 10px;
				right: -10px;
				width: 1px;
				height: 18px;
				background-color: white; } }
		.lang-ch {
			padding: 10px; } }


	.overflow-wrapper {
		width: 100%;
		height: 100vh;
		padding-top: 20px;
		overflow: scroll; }


	// animated hamburger to X menu icon
	$button-size: 25px;
	$transition: .3s;
	$color: #797979;

	.lines-button {
		position: absolute;
		top: 6px;
		left: -60px;
		z-index: 9999;
		display: none;
		width: 50px;
		height: 50px;
		padding: 8px 10px 15px;
		background: transparent;
		transition: .3s;
		cursor: pointer;
		user-select: none;
		appearance: none;
		border: none;
		opacity: 1;

		&:focus {
			outline: 0; }

		&.hidden {
			display: none; } }


	@mixin line {
		display: inline-block;
		width: $button-size;
		height: 3px;
		background: $color;
		transition: $transition;
		border-radius: 3px; }


	.lines {
		@include line; //create middle line
		position: relative;

		/*create the upper and lower lines as pseudo-elements of the middle line*/
		&:before,
		&:after {
			@include line;
			position: absolute;
			left: 0;
			content: '';
			transform-origin: $button-size/14 center; }

		&:before {
			top: 8px; }
		&:after {
			top: -8px; } }

	//animating
	.lines-button.x.close .lines {
		/*hide the middle line*/
		background: transparent;

		/*overlay the lines by setting both their top values to 0*/
		&:before,
		&:after {
			transform-origin: 50% 50%;
			top: 0;
			width: $button-size; }

		// rotate the lines to form the x shape
		&:before {
			transform: rotate3d(0,0,1,45deg); }
		&:after {
			transform: rotate3d(0,0,1,-45deg); } }
	// end of animated hamburger icon







	//mobile expandable nav links
	.mobile-nav .expand-menu {
		line-height: 0;
		padding: 10px 30px 5px; }

	.mobile-dropdown-wrapper {
		display: inline-block;
		width: calc(100% + 60px);
		height: 140px;
		margin-left: -30px;
		overflow: hidden;
		transition: height .3s, opacity .3s;

		li {
			// height: 20px
			padding: 12px;
			padding-left: 42px;
			// margin-bottom: 15px

			&:first-of-type {
				margin-top: 5px;
				// height: 25px
 } } }				// padding-top: 17px

	.fec_list_mobile {
		height: 186px; }

	.active-bg {
		&:active,
		&:hover {
			background-color: black; } }


	.menu-collapse {
		height: 0;
		opacity: 0; }


	.inner-container {
		position: relative;
		width: 90%;
		max-width: 1100px;
		margin: 0 auto; }

	.nav-upper {
		height: 170px;
		padding-top: 25px;

		.container {
			width: 100%;
			text-align: center; }

		img {
			// display: block
			width: 470px;
			// height: 50px
			margin: 40px auto; }

		ul {
			position: absolute;
			top: 90px;
			right: 0;

			li, a {
				display: inline;
				color: #666666;
				font-size: 18px; }

			li {
				font-family: $secondary-style;
				font-weight: bold;

				a {
					font-weight: normal; } } }

		.lang-en {
			position: relative;
			margin-right: 15px;

			&:after {
				content: "";
				position: absolute;
				top: 2px;
				right: -12px;
				width: 2px;
				height: 20px;
				background-color: #666666; } } }


	.nav-lower {
		height: 70px;
		background-color: $theme-blue;

		ul {
			display: table;
			justify-content: center;
			padding-top: 23px;
			margin: 0px auto;

			li {
				display: table-cell;
				padding: 0 20px; }

			a {
				color: white;
				font-size: 18px;
				font-weight: bold;

				&:hover {
					color: white; }

				&.inactive-page {
					opacity: .5;

					&:after {
						opacity: .5; } }

				&.active-page {
					opacity: 1;

					&:after {
						opacity: 1; } } }


			img {
				width: 24px;
				height: 24px;
				opacity: .5;
				transition: opacity .2s;

				&:hover {
					opacity: 1; } }


			.search-btn:hover {
				cursor: pointer; } }


		.no-link {
			pointer-events: none; }


		.dropdown-nav {
			position: relative;
			top: 0px;
			height: 50px;
			color: white;
			font-size: 18px;

			.main-link {
				position: relative;

				&:after {
					content: url("../../../assets/img/icons/btn-dropdown.svg");
					position: absolute;
					top: -2px;
					right: -16px; } }


			&:hover {
				cursor: pointer; }

			&:hover>.sub-nav-list {
				display: block;
				opacity: 1;
				pointer-events: auto;
				box-shadow: $box-shadow3;
				transition: opacity .1s .1s; }

			.sub-nav-list {
				display: none;
				position: absolute;
				bottom: -145px;
				left: -50%;
				width: 220px;
				height: auto;
				padding: 25px 0 5px;
				border-radius: 4px;
				background-color: white;
				opacity: 0;
				pointer-events: none;
				z-index: 101;
				transition: opacity .1s;

				&:before {
					content: "";
					position: absolute;
					top: -14px;
					left: calc(50% - 7px);
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 8px 7px;
					border-color: transparent transparent white transparent; }

				a {
					display: inline-block;
					width: 100%;
					color: $text-gray;
					font-weight: normal;
					transition: color .2s;
					opacity: 1;

					&:hover {
						color: $theme-orange;
						display: block; } }

				li {
					display: block;
					margin-bottom: 20px; }

				a {
					font-size: 18px; } }

			.fec_list {
					bottom: -101px;
					width: 221px;
					left: -20%; }

			.news_list {
					bottom: -101px;
					width: 201px;
					left: -20%; }
			.events_list {
					bottom: -101px;
					width: 201px;
					left: -20%; } } } }




@media all and (max-width: 1120px) {
	#bootstrapoverride nav {
		height: 180px;

		.inner-container {
			position: relative;
			width: 90%;
			max-width: 1100px;
			margin: 0 auto; }

		.nav-upper {
			//height: 140px
			//padding-top: 30px

			img {
				width: auto;
				height: 60px; }


			ul {
				top: 60px; } }


		.nav-lower {
			height: 40px;

			ul {
				padding-top: 10px;

				li {
					padding: 0 18px; }

				a {
					font-size: 14px; } }

			.dropdown-nav {
				position: relative;
				top: -1px;
				height: 50px;
				color: white;
				font-size: 18px;

				.show {
					opacity: 1;
					display: block; }

				&:hover {
					cursor: pointer; }

				&:hover .sub-nav-list {
					opacity: 1;
					pointer-events: auto;
					box-shadow: $box-shadow;
					transition: opacity .1s .1s; }

				&:hover + .sub-nav-bg {
					height: 65px;
					box-shadow: $box-shadow; }

				.sub-nav-list {
					display: block;
					position: absolute;
					bottom: -135px;
					left: -70px;
					width: 220px;
					height: auto;
					padding: 25px 0 5px;
					background-color: white;

					&:before {
						top: -14px;
						left: 50%; }

					li {
						display: block;
						margin-bottom: 20px; }

					a {
						font-size: 18px; } }

				.fec_list {
					bottom: -178px; } } }


		.sub-nav-bg {
			display: none; } } }



@media all and (max-width: 720px) {
	#bootstrapoverride nav {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 0;
		background-color: white;
		border-bottom: none;
		z-index: 1001;


		.inner-container {
			position: relative;
			width: 90%;
			max-width: 1100px;
			margin: 0 auto; }

		.nav-upper {
			display: none; }


		.nav-lower {
			display: none; }


		.mobile-nav {
			display: block;

			.lines-button {
				display: inline-block; }

			& ul {
				list-style-type: none; } } } }
