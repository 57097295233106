#bootstrapoverride .events-container {
	max-width: 1200px;
	// text-align: justify


	.event-wrapper {
		position: relative;
		width: 100%;
		height: 430px;
		margin-bottom: 30px;
		// text-align: justify
		overflow: hidden;

		&:hover .date-box {
			background-color: $theme-orange; } }

	.date-box {
		position: absolute;
		top: 0;
		left: 0;
		width: 80px;
		height: 80px;
		background-color: #027eba;
		padding-top: 18px;
		border-radius: 1px;
		text-align: center;
		z-index: 1;
		transition: background-color .3s;

		span {
			color: white;
			font-size: 22px;
			font-family: $secondary-style; } }

	.page-title {
		margin-bottom: 50px; }

	.img-wrapper {
		width: 100%;
		margin-bottom: 18px;

		img {
			height: 210px; } }

	.event-title {
		height: 24px;
		color: $text-black;
		font-size: 18px;
		font-weight: bold;
		line-height: 24px;
		margin-bottom: 18px;
		overflow: hidden; }

	.content {
		word-break: normal;
		max-height: 120px;
		overflow: hidden; }
	a {
		color: #027eba; }

	.share {
		display: flex;
		font-weight: bold;
		margin-bottom: 18px;

		img {
			width: 20px;
			height: 21px;
			margin-right: 10px; }
		a {
			color: #027eba;
			transition: color .2s; }

		a:hover {
			color: $theme-orange; } } }


#bootstrapoverride .event-detail-container {
	width: 100%;
	max-width: 875px;
	margin: 60px auto 50px;
	padding-bottom: 0;

	.event-detail-inner-container {
		padding: 35px 60px;
		background-color: white; }

	.event-heading {
		position: relative;
		width: 100%;
		height: 80px;
		margin-bottom: 25px; }

	.event-title {
		position: absolute;
		top: 15px;
		left: 100px; }

	.share {
		position: absolute;
		top: 45px;
		left: 100px;
		cursor: pointer;

		&:hover a {
			color: $theme-orange; } }

	p {
		margin-bottom: 30px;

		img {
			max-width: 100%; } }


	.event-picture {
		position: relative;
		display: none;
		width: 100%;

		.date-box {
			width: 60px;
			height: 60px;
			padding-top: 8px;

			span {
				font-size: 18px; } }

		.img-wrapper img {
			height: 150px; } } }





@media all and (max-width: $lg) {
	#bootstrapoverride .events-container {
		padding: 60px 40px 80px; } }


@media (max-width: $sm) {
	#bootstrapoverride .events-container {
		padding: 60px 20px 80px;

		.event-wrapper p {
			word-break: normal; }

		.img-wrapper {
			margin-bottom: 15px; }

		.event-title {
			margin-bottom: 10px; }

		.share {
			margin-bottom: 10px;
			margin-top: 15px; } }


	#bootstrapoverride .event-detail-container {
		margin: 10px auto 30px;
		padding: 30px 20px 60px;

		.event-picture {
			display: block; }


		.event-heading {
			height: auto;
			margin-bottom: 10px;

			.date-box {
				display: none; } }

		.event-title {
			position: static; }

		.share {
			position: static;
			margin-top: 0; }

		p {
			margin-bottom: 15px;

			img {
				width: 100%;
				height: auto;
				margin-bottom: 15px; } } } }





